import { useEffect, useState } from "react"
import NavBar from "../Navbar"
import pdf from '../../assets/pdf.png'
import Slider from "react-slick";
import { Card, Col, Row } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"
import ProductsService from "../../services/ProductsService"
import BannerService from "../../services/BannerService"
import ProductsMHNService from "../../services/ProductsMHNService";
import './style.css'

const PreumiumProducts = () =>{
    const [viewAll, setViewAll] = useState(false)
    const [products, setProducts] = useState([])
    const [highPressur, setHighPressur] = useState([])
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            }
          ]
    };
    const productsService = new ProductsService()
    const [pdfF, setPdfF] = useState('')
    const bannerService = new BannerService()
    const location = useLocation()

    useEffect(()=>{
      bannerService?.getList().then(res=>{
        if(res?.status === 200){
          let info = res?.data?.data?.filter(c => !!c?.catalog)
          if(info?.length > 0){
            setPdfF(info[0].catalog)
          } 
        }
      })
    },[])

    useEffect(()=>{
        let params = {
            limit: 9,
            offset: 0,
        }
        productsService?.getList({ 
            ...params,
            type: 'high_pressure' 
        }).then(res=>{
        if(res?.status === 200){
            setHighPressur(res?.data?.data?.data?.slice(0, 15))
        }
      })
    },[])

    useEffect(()=>{
        let params = {
            limit: 8,
            offset: 0,
        }
        if(viewAll) params = {}
        if(location.state) params['search'] = location.state
        if(location?.pathname?.includes('/categories/')) params['category_id'] = location?.pathname?.split('/')[2]
        new ProductsMHNService()?.getList({ ...params }).then(res=>{
        if(res?.status === 200){
            setProducts(res?.data?.data?.data)
        }
      })
    },[viewAll,location])


    return <>
    <NavBar />
    <div className="container PreumiumProducts" style={{marginTop: '10rem'}}>
        <h1 className="primary mb-0">Hasan Al Naser</h1>
        <h1 className="primary">Premium Products</h1>

        <div className='cont p-5' style={{backgroundColor: '#dedede30',marginTop: '5rem'}}>
            <div className="text-center mb-4">
                    <h5 className="primary mb-1" style={{fontWeight: '600'}}> Products we Provide</h5>
                    <h5>Shop By High Pressure Laminate Sheets</h5>
                    <Link to={`/high-pressure`} className="view">View All</Link>
            </div>
            {highPressur?.length > 0 ? <div className="slider-container">
                <Slider {...settings}>
                    {highPressur?.map(product=>{
                        return <div className="mb-3" key={product?.id}>
                            <Card className="w-75 d-flex p-2" style={{border: 'none',flexFlow: 'row' ,gap: '16px'}}>
                                <div style={{borderRadius: '5px'}}>
                                    <img 
                                        src={product.show_product_images[0]?.url} 
                                        alt='product' 
                                        style={{borderRadius: '8px'}}
                                        width={100}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <h4 className="mb-0 mt-1">{product.title}</h4>
                                    <Link to={`/products/${product.id}`} state={product} className="view">View</Link>
                                </div>
                            </Card>
                        </div>
                    })}
                </Slider>
            </div> : <h4 className="text-center primary mt-5">No Products Found...</h4>}
        </div>

        <Row>
            <Col md={12} className='mb-3 mt-4'>
                <div className="text-center">
                    <h5 className="primary mb-1" style={{fontWeight: '600'}}>The Latest</h5>
                    <h5>Premium Products</h5>
                </div>
            </Col>
            {products?.length > 0 ? products?.map(product=>{
                return <Col md={3} xs={6} className='mb-3' key={product?.id}>
                    <Link to={`/product/${product.id}`} state={product} style={{textDecoration: 'none'}}>
                    <Card className="text-center h-100" style={{border: 'none', boxShadow: '0 0 7px #ebebeb'}}>
                        <div style={{borderRadius: '5px'}} className='p-2 h-100'>
                            <img 
                                src={product.product_images[0]?.url} 
                                alt='product' 
                                className="w-100 h-100" 
                            />
                        </div>
                        <h4 className="p-2">{product.name_en}</h4>
                    </Card>
                    </Link>
                </Col>
            }) : <h4 className="text-center primary mt-5">No Products Found...</h4>}
        </Row>
        {(!viewAll && products?.length > 0)&& <div className="text-center mt-4">
            <button className="viewAll" onClick={()=> setViewAll(true)}>View All</button>
        </div>}

        <div className="banner bg-blue">
            <Row>
                <Col md={4}>
                    <p style={{fontWeight: '600'}}>Download Our Catalog</p>
                    <h3>EXPLORE ALL HASAN AL NASER PRODUCTS</h3>
                </Col>
                <Col md={5}></Col>
                <Col md={3}>
                    <div>
                        <img src={pdf} alt='pdf' width={90} className='d-block mb-4'></img>
                        <a href={pdfF} target="_blank" className="viewAll" rel="noreferrer">Download</a>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
    </>
}
export default PreumiumProducts