import BaseService from "./BaseService";
import http from './HttpService'
import { API_BASE_URL_ENV } from "../common/common";

const apiEndpoint = API_BASE_URL_ENV() + "/product";

export default class ProductsMHNService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }
    getProdust(id){
        return http.get(`${apiEndpoint}/${id}`)
    }
}
