import { useEffect, useState } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from "../Navbar";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper/modules"
import 'swiper/css/pagination';
import "./style.css"
import 'swiper/css'
import ProductsMHNService from "../../services/ProductsMHNService";

const CustomProduct = () => {
    const [data, setData] = useState({})
    const location = useLocation()
    const navigate = useNavigate()
    const [currentImage, setCurrentImage] = useState({})

    useEffect(()=>{
        let id = location?.pathname?.split('/')[2]
        new ProductsMHNService().getProdust(id).then(res=>{
            if(res?.status){
                let product = res?.data?.data?.product
                setData(product)
                setCurrentImage(product?.product_images[0])
            }
        })
    },[])
    return <>
        <NavBar />
        <div className="container products" style={{marginTop: '10rem'}}>
            <Row>
                <Col md={5} className='text-center mb-5'>
                    <img src={currentImage?.url} alt='product' className="w-75" />
                    <div className="mt-4">
                    <Swiper
                        modules={[Pagination]}
                        slidesPerView={3}
                        spaceBetween={10}
                        pagination={{
                            clickable: true
                        }}
                        breakpoints={{
                            640: { slidesPerView: 3 },
                            768: { slidesPerView: 3 },
                            1024: { slidesPerView: 3 },
                        }}
                        className="mySwiper"
                    >
                        {data?.product_images?.map((img,index)=>{
                            if(img?.id === currentImage?.id){
                                return ''
                            } 
                            return <SwiperSlide key={index}>
                                <img 
                                    src={img?.url} 
                                    alt={index} 
                                    key={index} 
                                    style={{
                                        border: img?.id === currentImage?.id ? '1px solid' : '',
                                    }}
                                    className='w-100 h-100' 
                                    onClick={()=> setCurrentImage(img)}
                                />  
                            </SwiperSlide>
                        })}
                    </Swiper>
                    </div>
                </Col>
                <Col md={7}>
                    <div>
                        <h1>{data?.name_en}</h1>
                        <Badge 
                            className="mb-4 p-2" 
                            variant='primary'
                            style={{
                                textTransform: 'capitalize',
                                fontSize: '18px'
                            }}
                        >
                            {data?.category?.name_en}
                        </Badge>
                        <p className="text-primary">About Product</p>
                        <p dangerouslySetInnerHTML={{ __html: data?.description_en }}></p> 
                        <a 
                            target="_blank"
                            className="btn btn-primary mt-4"
                            style={{
                                background: 'var(--primary)',
                                borderColor: 'var(--primary)',
                                width: '10rem'
                            }}
                            href={`https://www.masterhn.com/products/product/${data?.id}`} 
                            rel="noreferrer"
                        >Buy this Product</a>
                    </div>
                </Col>
            </Row>
        </div>
    </>
}
export default CustomProduct;