import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CategoriesService from '../../services/CategoriesService';
import NavBar from '../Navbar';
import './style.css'

const Categories = () => {
  const [data, setData] = useState([])
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const categoriesService = new CategoriesService()

  useEffect(()=>{
    setLoader(true)
    categoriesService?.getList().then(res=>{
      if(res?.status === 200){
        let info = res?.data?.data?.data
        setData(info)
      }
      setLoader(false)
    }).catch(()=> setLoader(false))
  },[])

  return (<>
  <NavBar />
  <div className='categories'>
    <div className='row mt-4'>
      {loader ? <div className='d-flex justify-content-center py-5'>
      </div> : data?.map((category) => {
        return <div className='col-md-3 mb-4 col-6' key={category?.id}>
          <div className='cate h-100 position-relative' onClick={()=> navigate(`/categories/${category?.id}`)}>
            <img src={category?.image} alt={category?.name_en} className='img w-100 h-100' />
            <div className='desc'>
              <p>{category.name_en}</p>
              <Link to='/'>Explore All</Link>
            </div>
          </div>
        </div>
      })}
    </div>
    </div>
    </>);
};

export default Categories;
