import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import Home from './pages/Home';
import Footer from './pages/Footer';
import { useEffect } from 'react';
import AOS from 'aos';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Error404 from './common/Error404';
import "./icons/line-awesome/css/line-awesome.min.css";
import PreumiumProducts from './pages/PreumiumProducts';
import Product from './pages/Product';
import AboutUs from './pages/AboutUs';
import HighPressur from './pages/HighPressur';
import ScrollToTop from './common/ScrollToTop';
import Products from './pages/Products';
import Categories from './pages/Categories';
import CustomProduct from './pages/CustomProduct';

function App() {
  useEffect(()=>{
    AOS.init();
  },[])

  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />}  />
          <Route path="/categories/:id" element={<PreumiumProducts />} />
          <Route path="/preumium-products" element={<PreumiumProducts />} />
          <Route path="/about-us" element={<AboutUs />}  />
          <Route path="/high-pressure" element={<HighPressur />}  />
          <Route path="/categories" element={<Categories />}  />
          <Route path="/products" element={<Products />}  />
          <Route path="/products/:id" element={<Product />}  />
          <Route path="/product/:id" element={<CustomProduct />}  />
          <Route path="*" element={<Error404 />}  />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
